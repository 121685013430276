<template>
  <div>
    <div class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>作业评分</div>
      <el-row  style="margin-bottom: 20px;margin-top:-30px ;float: right;">
        <el-col >
          <span class="report-title">选择周次</span>
          <el-select v-model="weekValue" placeholder="请选择" size="mini" clearable @change="currenyChange">
            <el-option v-for="(item, index) in weekList" :key="index"  :label="item.currentWeek"
              :value="item.startTime + '/' + item.endTime">
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 15px" size="mini" @click="getDrafrList" icon="el-icon-search">查询</el-button>
          <el-button type="warning" style="margin-left: 15px; float: right;" size="mini" @click="gveScore">评分</el-button>
          <el-button type="primary" style="margin-left: 15px; float: right;" size="mini" @click="exportExc">导出报表</el-button>
        
        </el-col>
      </el-row>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize"
        :currentPage="currentPage" :total="total" @sizeChange="sizeChanges" @currentChange="currentChanges"
         :tableColumn="startList" :tableData="spreadTable"></spread-table>
         <!-- @clickButton="clickButton" 此页面未使用该事件，如发现不适，在  SpreadTable 组件添加上即可 -->
    </div>

  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted,getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import SpreadTable from '@/components/SpreadTable'
import WangEditor from "@/components/WangEnduit";

import { useRouter } from "vue-router";
import { findEvenWeeksByCourseId, findScoreByCourseIdOrAcidAndUserId, calScoreByCourseId, exportReport } from "./homeApi";
import { dealDate } from "../../course-manage/upreport-manage/Upreport-State/startMethods";
import { getGroupTables } from "../../course-manage/group-manage/groupMethods";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    WangEditor,
  },
  props:{
    operation:{}
  },
  setup() {
    let data = reactive({
      weekValue: '',
      vueExample:{},
      weekList: [],
      startList: [
        { name: '账户ID', text: 'acid' },
        { name: '学生ID', text: 'userId' },
        { name: '姓名', text: 'name' },
        { name: '学生角色', text: 'memberRoleName' },
        { name: '课程ID', text: 'courseId' },
        { name: '基础分', text: 'basescore' },
        { name: '叠加分', text: 'changedscore' },
        { name: '平时分总分', text: 'allscore' },
        { name: '计算开始时间', text: 'begindate' },
        { name: '计算结束时间', text: 'enddate' },
        { name: '评分信息', text: 'msg' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      spreadTable: [],
      tableHeight: 450,
      param: {}
    })
    onMounted(() => {
      checkScoke()
      data.vueExample = getCurrentInstance()
    })

    let getDrafrList = () => {
      let param = JSON.stringify(data.param)
      if (param == "{}") {
        ElMessage.warning('请选择周次')
      } else {
        data.loading = true
      let parma = {
        courseId: sessionStorage.getItem('classId'),
        startTime: data.param.slice(0, 8),
        endTime: data.param.slice(9),
        pageSize: data.pageSize,
        page: data.currentPage,
      }
      findScoreByCourseIdOrAcidAndUserId(parma).then(res => {
        if (res.data.code === '200') {
          data.spreadTable = res.data.data.scorePrecedures.list
          data.total = res.data.data.scorePrecedures.total
          dealDate(data.spreadTable, ['begindate', 'enddate'])
          data.loading = false
        }
      })
      }
      
    }
    let checkScoke = () => {
      let param = {
        courseId: sessionStorage.getItem('classId'),
      }
      findEvenWeeksByCourseId(param).then(res => {
        if (res.data.code === '200') {
          data.weekList = res.data.data.timeRoundList
        }
      })
    }

    let currenyChange = e => {
      data.param = e
    }

    // 切换条数
    let sizeChanges = (val) => {
      data.pageSize = val
      getDrafrList()
    }
    // 切换页数
    let currentChanges = (val) => {
      data.currentPage = val
      getDrafrList()
    }

    // 导出报表
    let exportExc = () => {
      // window.location.href='/tradelab/export/exportHistoricalPositions?acid='+
      //     JSON.parse(sessionStorage.getItem('acid'))
      let param = JSON.stringify(data.param)
      if (param == "{}") {
        ElMessage.warning('请选择周次')
      } else {
        data.loading = true
        let url = "/tradelab/systemRating/exportScore"
        let params={
          // fileName:val.item.fileName,
          courseId: sessionStorage.getItem('classId'),
          startTime: data.param.slice(0, 8),
          endTime: data.param.slice(9),
        }
        const {proxy} = data.vueExample
        proxy.$DownloadAsExcelPost(url, params, '评分报表.xlsx')
      }
      data.loading = false
    }

    // 评分
    let gveScore = () => {
      let param = JSON.stringify(data.param)
      if (param == "{}") {
        ElMessage.warning('请选择周次')
      } else {
        let param = {
          courseId: sessionStorage.getItem('classId'),
          startTime: data.param.slice(0, 8),
          endTime: data.param.slice(9),
        }
        calScoreByCourseId(param).then(res => {
          if (res.status == 200) {
            ElMessage.success('已评分')
          } else {
            ElMessage.error('评分失败')
          }
        })
      }
    }
    return {
      ...toRefs(data),
      checkScoke,
      currenyChange,
      getDrafrList,
      sizeChanges,
      currentChanges,
      exportExc,
      gveScore
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background:  #3B6EFB;
}

.report-title {
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
</style>
